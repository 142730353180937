<template>
  <div>
    <JayaTitle
        classType="page-title"
        class="py-1"
        titleName="Mon Jardin"/>

    <b-card class="max-w-md">
      <b-alert v-if="modeValue === 'edit' && !onBoarding.garden" show variant="warning" class="p-1 text-center">
        <p class="text-dark">Bienvenue sur l'espace gestion de jardin de Hortilio</p>
        <p class="text-dark">Dites-nous en plus sur votre jardin ! </p>
        <p class="text-dark font-weight-normal">
          Un jardin Hortilio correspond a une <strong>parcelle privée</strong> localisée, ce qui nous permet d'
          <strong>adapter le comportement</strong> de la plateforme à sa position : <i>conseils, évènements, lieux
          d'intérêt autour de vous, plantes indigènes, forum local...</i> C'est pour cela que nous vous demandons le
          <strong>
            code postal</strong> de votre jardin.
        </p>
        <p class="text-dark font-weight-normal">
          Pour vous proposer les conseils les plus adaptés possibles à votre jardin, nous avons besoin de savoir
          s'il est sur un <strong>balcon (terrasse) ou en pleine terre</strong>.
        </p>
      </b-alert>

      <b-alert v-if="modeValue === 'create'" show variant="warning" class="p-1">
        <p>Vous êtes sur le point de créer un nouveau jardin sur Hortilio</p>
        <p class="text-dark font-weight-normal">
          Un jardin Hortilio correspond a une <strong>parcelle privée</strong> localisée, ce qui nous permet d'
          <strong>adapter le comportement</strong> de la plateforme à sa position : <i>conseils, évènements, lieux
          d'intérêt autour de vous, plantes indigènes...</i>
        </p>
        <p class="text-dark font-weight-normal">
          <strong>Créez un nouveau jardin</strong> si, par exemple, vous vous occupez aussi d'un autre jardin -
          <i>dans une autre ville, ou une autre région</i> ! Les conseils pourront être distincts pour ces deux
          jardins.
        </p>
        <p class="text-dark font-weight-normal">
          <strong class="text-warning">En revanche</strong>, si vous avez des parties distinctes sur votre parcelle -
          <i>par exemple une
            zone ornementale et, plus loin, un verger </i>- il n'est <strong>pas pertinent</strong> de créer deux
          jardins Hortilio distincts.
        </p>
      </b-alert>

      <b-alert v-if="modeValue === 'edit' && onBoarding.garden" show variant="warning" class="p-1">
        <p>Vous êtes sur le point de modifier votre jardin</p>
        <p class="text-dark font-weight-normal">
          Attention, ne changez le code postal que si vous avez fait une erreur à la création.
        </p>
        <p class="text-dark font-weight-normal font-italic">
          En cas de déménagement, vous pouvez en créer un nouveau sur la page de gestion de votre compte Hortilio,
          et supprimer celui-ci.
        </p>
      </b-alert>
      <validation-observer v-slot="{ handleSubmit, invalid }">
        <b-form @submit.prevent="modeValue === 'display' ? null : handleSubmit(onSubmit)">

          <b-form-group>
            <JayaTooltip class="mt-1" title="Nom du jardin">
              <label>
                <span class="font-small-4">Nom du jardin </span>
                <feather-icon
                    icon="InfoIcon"
                    class="text-info"
                    size="16"/>
              </label>
              <div slot="text">
                Le nom de votre jardin est utile seulement à vous, pous savoir de quoi il retourne !
              </div>
            </JayaTooltip>
            <validation-provider v-slot="{errors}" name="Nom du jardin" rules="required|max:50" immediate>
              <b-form-input
                  v-model="form.name"
                  :disabled="modeValue === 'display'"
                  name="text"/>
              <b-form-text class="text-danger">{{ errors[0] }}</b-form-text>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <JayaTooltip class="mt-1" title="Type de jardin">
              <label>
                <span class="font-small-4">Type de jardin </span>
                <feather-icon
                    icon="InfoIcon"
                    class="text-info"
                    size="16"/>
              </label>
              <div slot="text">
                Le comportement de Hortilio s'adapate à votre réponse, selon que votre jardin soit sur un balcon / une
                terrasse (c'est à dire constitué de plantes en pots), ou bien qu'il soit en pleine terre.
              </div>
            </JayaTooltip>
            <validation-provider v-slot="{errors}" rules="required" immediate>
              <b-form-radio-group v-model="form.is_balcony" :disabled="modeValue === 'display'">
                <b-form-radio name="balcony-radios" :value="true" class="pl-2">
                  Balcon ou Terrasse
                </b-form-radio>
                <b-form-radio name="balcony-radios" :value="false" class="pl-2">
                  Pleine terre
                </b-form-radio>
              </b-form-radio-group>
              <b-form-text class="text-danger">{{ errors[0] }}</b-form-text>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <JayaTooltip class="mt-1" title="Code postal">
              <label>
                <span class="font-small-4">Quel est son code postal ? </span>
                <feather-icon
                    icon="InfoIcon"
                    class="text-info"
                    size="16"/>
              </label>
              <div slot="text">
                Le code postal du jardin permet de vous rattacher à des collectivités territoriales (commune,
                métropole, département) - si présentes sur Hortilio. Grâce à cette information,
                vous pouvez accédez à du contenu spécifique à votre territoire : lieux, évènements, espèces
                végétales locales... Lorsque le code postal correspond à une collectivité existante sur Hortilio,
                nous lui transmettons des statistiques anonymes sur les pratiques, le contenu et les aménagaments
                des jardins privés de son territoire. Vous pouvez consulter les Conditions Générales d'utilisation,
                et la Charte d'Utilisation des Données Personnelles pour en savoir plus.
              </div>
            </JayaTooltip>
            <validation-provider v-slot="{errors}" rules="required|validZipCode">
              <b-input-group class="flex-nowrap">
                <b-input-group-prepend is-text>
                  <feather-icon icon="HomeIcon"/>
                </b-input-group-prepend>
                <vue-simple-suggest
                    v-model="form.zip_code"
                    :disabled="modeValue === 'display'"
                    :debounce="200"
                    :list="zipCodeSearch"
                    :max-suggestions="30"
                    :min-length="2"
                    :nullable-select="false"
                    :prevent-submit="true"
                    class="flex-grow-1"
                    display-attribute="display"
                    placeholder="Code postal"
                    value-attribute="id"
                    @select="setUpZipCode"/>
              </b-input-group>
              <b-form-text class="text-danger">{{ errors[0] }}</b-form-text>
            </validation-provider>

            <div class="mt-2" v-if="modeValue === 'display'">
              <p v-if="activeGarden.local_authorities_infos.length>1">
                Votre jardin est rattaché aux collectivités Hortilio suivantes :
                <i>{{ activeGarden.local_authorities_infos.map(item => item.name).join(', ') }}.</i>
              </p>
              <p v-else-if="activeGarden.local_authorities_infos.length===1">
                Votre jardin est rattaché à la collectivité suivante :
                <i>{{ activeGarden.local_authorities_infos[0].name }}.</i>
              </p>
              <p v-else>Votre jardin n'est rattaché à aucune collectivité présente sur Hortilio</p>
            </div>
          </b-form-group>

          <div class="text-center">
            <b-button v-if="modeValue === 'display' && manyGardens" @click="promptDelete=true" variant="flat-danger"
                      class="m-50">
              Supprimer le jardin
            </b-button>
          </div>

          <div class="text-center">
            <b-button @click.prevent="goToHome()" type="button" variant="outline-secondary" class="m-50">
              Retour
            </b-button>
            <b-button v-if="modeValue === 'display'"
                      @click.prevent="goToEdit()"
                      type="button" variant="primary"
                      class="m-50">
              Débuter la modification
            </b-button>
            <b-button v-if="modeValue !== 'display'" type="submit" variant="primary" class="m-50"
                      :disabled="invalid">
              {{ modeValue === 'create' ? "Créer le jardin" : "Mettre à jour le jardin" }}
            </b-button>
          </div>
        </b-form>
        <b-modal
            v-model="promptDelete"
            ok-title="Supprimer"
            ok-variant="danger"
            cancel-title="Annuler"
            cancel-variant="outline-secondary"
            centered
            title="Suppression d'un jardin"
            @ok="deleteGarden">
          <b-alert show variant="danger" class="p-1">
            <p>Vous êtes sur le point de supprimer votre jardin</p>
            <p class="text-dark font-weight-normal">
              Attention, cette action est irréversible, vous perdrez toutes les plantes ajoutées, notes crées, quiz de
              biodiversité complétés...
            </p>
          </b-alert>
        </b-modal>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {ref, reactive, computed, onMounted, onUnmounted} from '@vue/composition-api'
import JayaTitle from '@/components/global/JayaTitle.vue'
import {extend, ValidationObserver, ValidationProvider} from 'vee-validate'
import {
  BForm,
  BFormGroup,
  BFormText,
  BFormInput,
  BFormRadio,
  BFormRadioGroup,
  BButton,
  BModal,
  BCard,
  BInputGroup,
  BInputGroupPrepend,
  BAlert,
} from "bootstrap-vue";
import {max_value, min_value, integer, required, max} from "vee-validate/dist/rules";
import {notify, pauseDifferentOnBoarding} from "@/globalMethods";
import store from '@/store'
import VueSimpleSuggest from "vue-simple-suggest";
import 'vue-simple-suggest/dist/styles.css'
import router from '@/router'

extend('min_value', {...min_value, message: "Ce champ doit être supérieur à {min}"});
extend('max_value', {...max_value, message: "Ce champ doit doit être inférieur à {max}"});
extend('integer', {...integer, message: "Ce champ doit être un nombre entier"});
extend('required', {...required, message: "Ce champ est requis"});
extend('max', {...max, message: 'Le champ "{_field_}" doit faire moins de {length} caractères'});


export default {
  name: 'ManageGarden',
  methods: {notify, pauseDifferentOnBoarding},
  components: {
    JayaTitle,
    BModal,
    BForm,
    BFormGroup,
    BFormText,
    BFormInput,
    BFormRadio,
    BFormRadioGroup,
    BButton,
    BCard,
    BInputGroup,
    BInputGroupPrepend,
    BAlert,
    ValidationObserver,
    ValidationProvider,
    VueSimpleSuggest,
  },
  props: {
    mode: {
      type: String,
      required: true,
      validator: value => ['create', 'edit', 'display'].includes(value)
    },
  },
  setup(props) {
    let pausedOnboardings = []
    onMounted(() => {
      pausedOnboardings = pauseDifferentOnBoarding("garden", store)
    })
    onUnmounted(() => {
      for (const pausedOnBoarding of pausedOnboardings) {
        store.commit("account/START_ONBOARDING", pausedOnBoarding)
      }
    })
    let promptDelete = ref(false)
    let patchZipCode = ref(false)
    let onBoarding = computed(() => store.state.account.onBoarding)

    const gardens = computed(() => store.state.garden.gardens)
    const manyGardens = computed(() => store.state.garden.gardens.length > 1)
    const activeGarden = computed(() => gardens.value.find((e) => e.is_active))
    const activeGardenExtendedName = computed(() => {
      return activeGarden.value && activeGarden.value.zip_code !== null ?
          activeGarden.value.zip_code_details.zip_code + ' - ' + activeGarden.value.zip_code_details.name : null
    })

    let modeValue = ref(props.mode);
    const loading = function (value, message) {
      store.commit('app/UPDATE_MESSAGE', value ? message : null)
      store.commit('app/UPDATE_LOAD', value)
    }
    const form = reactive(
        props.mode === 'create' ?
            {
              name: "Mon nouveau jardin",
              is_balcony: null,
              zip_code: "",
            } : {
              name: activeGarden.value ? activeGarden.value.name : null,
              is_balcony: activeGarden.value.is_balcony,
              zip_code: activeGardenExtendedName.value,
            })

    const editingZipCode = reactive({
      id: null,
      display: form.zip_code
    })

    extend('validZipCode', {
      validate() {
        return editingZipCode && editingZipCode.display === form.zip_code
      },
      message: 'Code postal invalide : utilisez les suggestions'
    })

    const setMode = (mode) => {
      if (['create', 'edit', 'display'].includes(mode)) {
        modeValue.value = mode
      }
    }

    const goToHome = () => {
      router.push({name: 'jayaccueil'})
    }

    const goToEdit = () => {
      router.push({name: 'manage-garden', params: {mode: 'edit'}})
      setMode('edit')
    }

    const onSubmit = async () => {
      if (modeValue.value === 'create') {
        // Create garden
        const payload = {
          name: form.name,
          is_balcony: form.is_balcony,
          zip_code: editingZipCode.id
        }
        store.dispatch('garden/addGarden', payload)
            .then(() => {
              notify({
                title: "C'est parti !",
                color: "primary",
                text: "Votre jardin a bien été créé",
                time: 5000,
              })
              router.push({name: 'jayaccueil'})
            })
            .catch((error) => {
              if (error.response && error.response.status === 403 && error.response.data.detail === "Can't have more than 8 gardens") {
                this.$notify({
                  time: 4000,
                  color: "danger",
                  title: "Nombre de jardins trop important",
                  text: "Vous disposez déjà de 8 jardins, veuillez en supprimer un pour pouvoir vous abonner"
                })
              } else {
                notify({
                  title: "Erreur !",
                  color: "danger",
                  text: "Une erreur est survenue lors de la création de votre jardin",
                })
              }
            })
      } else if (modeValue.value === 'edit') {
        // Update garden
        const payload = {
          id: activeGarden.value.id,
          name: form.name,
          is_balcony: form.is_balcony,
        }
        if (patchZipCode.value) {
          payload['zip_code'] = editingZipCode.id
        }
        loading(true)
        store.dispatch('garden/updateGarden', payload)
            .then(() => {
              store.dispatch('account/updateOnBoarding', {id: onBoarding.value.id, garden: true})
                  .then(() => {
                    loading(false)
                    notify({
                      title: "C'est noté !",
                      color: "primary",
                      text: "Votre jardin a bien été mis à jour",
                    })
                    router.push({name: 'jayaccueil'})
                  }).catch(() => {
                loading(false)
                notify({
                  title: "Erreur !",
                  color: "danger",
                  text: "Une erreur est survenue lors de la mise à jour de l'onBoarding du jardin",
                })
              })
            })
            .catch(() => {
              loading(false)
              notify({
                title: "Erreur !",
                color: "danger",
                text: "Une erreur est survenue lors de la modification de votre jardin",
              })
            })
      }
    }

    const zipCodeSearch = async (searchText) => {
      return new Promise((resolve, reject) => {
        store.dispatch("zipCode/search", {search: searchText, limit: 30})
            .then(results => {
              //make nam + zipcode attribute
              resolve(results.data.results.map(obj => ({...obj, display: obj.zip_code + ' - ' + obj.name})))
            })
            .catch(error => {
              reject(error)
            })
      })
    }

    const setUpZipCode = (zipCode) => {
      if (zipCode && zipCode.id) {
        Object.assign(editingZipCode, zipCode);
        if (editingZipCode.display !== zipCode) {
          patchZipCode.value = true
        }
      } else {
        editingZipCode.value = null
      }
    }

    const deleteGarden = async () => {
      store.dispatch('garden/updateGarden', {id: activeGarden.value.id, state: 'DE'})
          .then(() => {
            notify({
              title: "C'est noté",
              color: "primary",
              text: "Votre jardin a bien été supprimé",
            })
            router.push({name: 'jayaccueil'})
          })
          .catch(() => {
            notify({
              title: "Erreur",
              color: "danger",
              text: "Une erreur est survenue lors de la suppression de votre jardin",
            })
          })
    }

    return {
      form,
      onSubmit,
      promptDelete,
      manyGardens,
      modeValue,
      setMode,
      goToHome,
      goToEdit,
      deleteGarden,
      zipCodeSearch,
      setUpZipCode,
      activeGarden,
      onBoarding,
    }
  }
}
</script>

<style>
</style>